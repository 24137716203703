<template>
  <div id="GDMap" :style="style"></div>
</template>

<script>
import router from "@/router";
import loadMap from "../../../../utils/loadMap";
import { getMapMaker } from "../../../../api/real";

export default {
  name: "MonitoryPointMapHome",
  components: {},
  props: ["height"],
  data() {
    return {
      style: { width: 100 + "%", height: this.height + "px" },
      // 加载的一些插件
      // 更多参考：https://lbs.amap.com/api/javascript-api/guide/abc/plugins#plugins
      plugins: [
        "AMap.Autocomplete",
        "AMap.PlaceSearch",
        "AMap.OverView",
        "AMap.MouseTool",
        "AMap.PolyEditor",
        "AMap.RectangleEditor",
        "AMap.DistrictLayer",
        "AMap.CustomLayer",
      ],
      // key
      key: "a1610d0afb249c02704d990f97572f83",
      //key: "64c880093eda5bd293e4d5c324e5131b", Autocomplete没有效果
      // 地图版本
      v: "1.4.4",
    };
  },
  created() {},
  mounted() {
    this.initMap();
    console.log("style:", this.style);
  },
  methods: {
    handleGoBack() {
      router.go(-1);
      this.$store.commit("closeCurrentTag", {
        $router: this.$router,
        $route: this.$route,
      });
    },
    // 高德地图加载
    initMap() {
      loadMap(this.key, this.plugins, this.v)
        .then((AMap) => {
          // 参数配置
          let map = new AMap.Map("GDMap", {
            zoom: 5,
          });
          // 获取数据
          getMapMaker().then((result) => {
            let posInfo = {};
            const mapMakerDtoList = result.data;
            for (const monitoryPointInfo of mapMakerDtoList) {
              const monitoryPointName = monitoryPointInfo.monitoryPointName;
              const mn = monitoryPointInfo.mn;
              const date = monitoryPointInfo.date;
              const flag = monitoryPointInfo.flag;
              const longitude = monitoryPointInfo.longitude;
              const latitude = monitoryPointInfo.latitude;
              const realData = monitoryPointInfo.data;
              let icon;
              if (flag === "T") {
                icon = new AMap.Icon({
                  size: new AMap.Size(50, 50),
                  imageSize: new AMap.Size(50, 50),
                  imageOffset: new AMap.Pixel(0, 0),
                  image: require("@/assets/img/red.png"),
                });
              } else if (flag === "D") {
                icon = new AMap.Icon({
                  size: new AMap.Size(50, 50),
                  imageSize: new AMap.Size(50, 50),
                  imageOffset: new AMap.Pixel(0, 0),
                  image: require("@/assets/img/yellow.png"),
                });
              } else {
                icon = new AMap.Icon({
                  size: new AMap.Size(50, 50),
                  imageSize: new AMap.Size(50, 50),
                  imageOffset: new AMap.Pixel(0, 0),
                  image: require("@/assets/img/green.png"),
                });
              }
              let marker = new AMap.Marker({
                position: new AMap.LngLat(longitude, latitude),
                title: monitoryPointName,
                cursor: "pointer",
                icon: icon,
              });
              // 渲染标记点
              marker.setLabel({
                offset: new AMap.Pixel(0, 0),
                content: "<div>" + monitoryPointName + "</div>",
                direction: "top",
              });
              map.add(marker);
              // 渲染窗体信息
              let info = [];
              info.push("<p>" + "监控点名称: " + monitoryPointName + "</p>");
              info.push("<p>" + "MN号: " + mn + "</p>");
              info.push("<p>" + "通信时间: " + date + "</p>");
              for (let key in realData) {
                info.push("<p>" + key + " : " + realData[key] + "</p>");
              }
              posInfo[monitoryPointName] = info;
              // 创建 infoWindow 实例
              let openWindow = new AMap.InfoWindow({
                content: info.join(""), //传入 dom 对象，或者 html 字符串
              });
              // 打开信息窗体
              let position = new AMap.LngLat(longitude, latitude);
              openWindow.open(map, position);
              console.log("position: ", JSON.stringify(position));
              console.log("info: ", JSON.stringify(info));
              // 添加坐标的点击事件
              AMap.event.addListener(marker, "click", function (e) {
                let monitoryPointNameEvent = e.target.w.title;
                let infoWindow = new AMap.InfoWindow({
                  content: posInfo[monitoryPointNameEvent].join(""),
                });
                infoWindow.open(map, e.target.getPosition());
              });
            }
          });
        })
        .catch((e) => {
          console.log("地图加载失败！", JSON.stringify(e));
        });
    },
  },
};
</script>

<style scoped></style>
