<template>
  <div>
    <el-row :gutter="5">
      <el-select
        @change="handleMonitoryNameChange"
        size="mini"
        v-model="select.MonitoryNameCode"
        placeholder="监控点名称"
        style="margin-left: 2%; margin-top: 2%"
      >
        <el-option
          v-for="item in options.MonitoryNameOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        @change="handlePollutionNameChange"
        size="mini"
        v-model="select.PollutionCode"
        placeholder="污染物"
        style="margin-left: 2%; margin-top: 2%"
      >
        <el-option
          v-for="item in options.PollutionOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-row>
    <!--    -->
    <home-detail-data
      :monitory-point-name="select.MonitoryNameCode"
    ></home-detail-data>
    <!--    -->
    <!-- 为 ECharts 准备一个具备大小（宽高）的 DOM -->
    <div id="main" style="width: 100%; height: 400px"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getChartsData } from "@/api/charts";
import { getPollutionOptions } from "@/api/pollution";
import { getMonitoryPointOptions, getUserInfo } from "../api/dashboard";
import { getDayStartAndEnd } from "../utils/Date";
import HomeDetailData from "./HomeDetailData";

let myChart;
export default {
  name: "HomeSelect",
  components: { HomeDetailData },
  props: { dialogVisible: Boolean, username: String },
  data() {
    return {
      visible: false,
      options: {
        PollutionOptions: [],
        MonitoryNameOptions: [],
      },
      select: {
        PollutionCode: "",
        MonitoryNameCode: "",
      },
      option: {
        name: "",
        unit: "",
        data: {
          categoryData: [],
          valueData: [],
        },
      },
      userInfo: {},
    };
  },
  mounted() {
    getUserInfo().then((result) => {
      this.userInfo = result.data;
      console.log("userInfo", JSON.stringify(this.userInfo));
      this.getOptions();
    });
  },
  methods: {
    // 监控点选择
    handleMonitoryNameChange(val) {
      console.log("change-monitory-name:", val);
      // 获取污染物options
      const monitoryPointName = this.select.MonitoryNameCode;
      let params = {
        monitoryPointName: monitoryPointName,
      };
      getPollutionOptions(params).then((result) => {
        this.options.PollutionOptions = result.data;
        this.select.PollutionCode = result.data[0].value;
        console.log("PollutionOptions", JSON.stringify(result.data));
        this.getChartsData();
      });
    },
    // 污染物选择
    handlePollutionNameChange(val) {
      console.log("select:", JSON.stringify(this.select.PollutionCode));
      console.log(val);
      this.select.PollutionCode = val;
      try {
        // 销毁
        myChart.dispose();
      } catch (e) {
        console.log(e);
      }
      // 获取统计图数据
      this.getChartsData();
    },

    getOptions() {
      // 获取监控点options
      getMonitoryPointOptions({ username: this.userInfo.username }).then(
        (result) => {
          this.options.MonitoryNameOptions = result.data;
          this.select.MonitoryNameCode = result.data[0].value;
          console.log("MonitoryNameOptions", JSON.stringify(result.data));
          console.log("MonitoryNameCode", this.select.MonitoryNameCode);
          // 获取污染物options
          const monitoryPointName = this.select.MonitoryNameCode;
          let params = {
            monitoryPointName: monitoryPointName,
          };
          getPollutionOptions(params).then((result) => {
            this.options.PollutionOptions = result.data;
            this.select.PollutionCode = result.data[0].value;
            console.log("result.data[0].value", result.data[0].value);
            console.log("PollutionOptions", JSON.stringify(result.data));
            this.getChartsData();
          });
        }
      );
    },
    // 获取统计图数据
    getChartsData() {
      const pollutionCode = this.select.PollutionCode;
      if (pollutionCode === "" || pollutionCode === undefined) {
        return;
      }
      const monitoryPointName = this.select.MonitoryNameCode;
      const beginDate = new Date(getDayStartAndEnd()[0]).getTime();
      const endDate = new Date(getDayStartAndEnd()[1]).getTime();
      const params = {
        monitoryPointName: monitoryPointName,
        pollutionCode: pollutionCode,
        beginDate: beginDate,
        endDate: endDate,
      };
      console.log("统计图params:", params);
      getChartsData(params)
        .then((result) => {
          this.option.data.categoryData = result.data.data.categoryData;
          this.option.data.valueData = result.data.data.valueData;
          this.option.name = result.data.name;
          this.option.unit = result.data.unit;
          console.log("统计图option:", this.option);
          this.handleCharts();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    father(label, prop) {
      console.log("this.father: ", label, prop);
      this.handlePollutionNameChange(prop);
    },
    // 渲染统计图
    handleCharts() {
      // 基于准备好的dom，初始化echarts实例
      myChart = echarts.init(document.getElementById("main"));

      const option = {
        title: {
          text: this.option.unit,
          left: 10,
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: false,
            },
            saveAsImage: {
              pixelRatio: 2,
            },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          bottom: 90,
        },
        dataZoom: [
          {
            type: "inside",
          },
          {
            type: "slider",
          },
        ],
        xAxis: {
          data: this.option.data.categoryData,
          silent: false,
          splitLine: {
            show: false,
          },
          splitArea: {
            show: false,
          },
        },
        yAxis: {
          splitArea: {
            show: false,
          },
        },
        series: [
          {
            name: this.option.name,
            type: "bar",
            data: this.option.data.valueData,
            // Set `large` for large data amount
            large: true,
          },
        ],
      };
      option && myChart.setOption(option);
    },
  },
};
</script>

<style scoped></style>
