<template>
  <div>
    <el-descriptions title="" :column="1" size="small" :border="true">
      <el-descriptions-item label="数据时间" width="100px">
        {{ dataTime }}
      </el-descriptions-item>
      <el-descriptions-item
        v-for="item in colHead"
        :key="item.column.label"
        :label="item.column.label"
        width="100px"
      >
        {{ tableData[0][item.column.prop] }}
        <el-button
          @click="handleDescriptionClick(item.column.label, item.column.prop)"
          size="mini"
          type="text"
        >
          切换
        </el-button>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
// import router from "@/router";
import { getTable } from "@/api/data-detail";
import { getDayStartAndEnd } from "../utils/Date";

export default {
  name: "HomeDetailData",
  components: {},
  props: { monitoryPointName: String },
  data() {
    return {
      list: [],
      secondColumns: undefined,
      exceedBoolean: true,
      tableData: [],
      colHead: [],
      monitoryPointType: "",
      dataTime: "",
    };
  },
  watch: {
    monitoryPointName() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      // 请求参数
      const param = {
        monitoryPointName: this.monitoryPointName,
        dataType: "real",
        dataStatus: "",
        pageSize: 1,
        currentPage: 1,
        beginDate: new Date(getDayStartAndEnd()[0]).getTime(),
        endDate: new Date(getDayStartAndEnd()[1]).getTime(),
      };
      console.log("请求参数:", JSON.stringify(param));
      getTable(param).then((result) => {
        const data = result.data;
        const list = [];
        data.commonPage.list.forEach((o) => {
          list.push(o.datagramData);
        });
        this.tableData = list;
        this.colHead = data.colHead;
        this.monitoryPointType = data.monitoryPointType;
        console.log(this.monitoryPointType);
        this.list = data.commonPage.list;
        console.log("colHead:", JSON.stringify(this.colHead));
        console.log("tableData:", JSON.stringify(this.tableData));
        this.dataTime = this.tableData[0]["dataTime"];
      });
    },
    handleDescriptionClick(label, prop) {
      // 调用父组件方法
      prop = prop.substring(0, prop.indexOf("-"));
      console.log(label, prop);
      this.$parent.father(label, prop);
    },
  },
};
</script>

<style scoped>
.el-tag {
  margin-left: 2px;
}
</style>
