<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="16">
        <el-card
          shadow="hover"
          :body-style="bodyStyle"
          class="mgb20"
          :style="style"
        >
          <monitory-point-map-home :height="clientHeight">
          </monitory-point-map-home>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover" :body-style="bodyStyle" :style="style">
          <home-select></home-select>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getMonitoryPointOptions, getUserInfo } from "../api/dashboard";
import MonitoryPointMapHome from "./data/real/map/MonitoryPointMapHome";
import HomeSelect from "./HomeSelect";

export default {
  name: "dashboard",
  data() {
    return {
      userInfo: "",
      style: {
        weight: 50 + "%",
        height: document.body.clientHeight - 120 + 200 + 400 + "px",
      },
      styleHeightDivide2: {
        weight: 50 + "%",
        height: (document.body.clientHeight - 120) / 2 + "px",
      },
      styleHeightDivide10: {
        weight: 50 + "%",
        height: ((document.body.clientHeight - 120) / 2 / 110) * 2 - 10 + "px",
      },
      styleHeightDivide10multiply8: {
        weight: 50 + "%",
        height: ((document.body.clientHeight - 120) / 2 / 10) * 8 + "px",
      },
      bodyStyle: { padding: "0px" },
      clientHeight: document.body.clientHeight - 120 + 200 + 400,
    };
  },
  components: { HomeSelect, MonitoryPointMapHome },
  computed: {},
  mounted() {
    getUserInfo().then((result) => {
      this.userInfo = result.data;
      console.log("userInfo", JSON.stringify(this.userInfo));
    });
  },
  methods: {
    getOptions(username) {
      const params = {
        username: username,
      };
      getMonitoryPointOptions(params).then((result) => {
        console.log("monitoryNames", JSON.stringify(result));
      });
    },
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.grid-content {
  display: flex;
  align-items: center;
  height: 100px;
}

.grid-cont-right {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.grid-num {
  font-size: 30px;
  font-weight: bold;
}

.grid-con-icon {
  font-size: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #fff;
}

.grid-con-1 .grid-con-icon {
  background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
  background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
  background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
  color: rgb(242, 94, 67);
}

.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}

.user-avator {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.user-info-cont {
  padding-left: 50px;
  flex: 1;
  font-size: 14px;
  color: #999;
}

.user-info-cont div:first-child {
  font-size: 30px;
  color: #222;
}

.user-info-list {
  font-size: 14px;
  color: #999;
  line-height: 25px;
  margin-bottom: 15px;
}

.user-info-list span {
  margin-left: 70px;
}

.mgb20 {
  margin-bottom: 20px;
}

.todo-item {
  font-size: 14px;
}

.todo-item-del {
  text-decoration: line-through;
  color: #999;
}

.schart {
  width: 100%;
  height: 300px;
}
</style>
